/* src/App.css */
.app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #ffffff;
  padding-top: 60px; /* Adjust padding to ensure content starts below header */
}

.app-header {
  width: 100%;
  background: rgba(0, 0, 0, 0.15); /* Darker, slightly transparent background */
  backdrop-filter: blur(8px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(10px); /* Apply blur effect for Safari */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Border to enhance glass effect */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px; /* Adjust padding for positioning */
  position: fixed; /* Anchor the header to the top */
  top: 0;
  left: 0;
  z-index: 1; /* Ensure the header is below the progress bar */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Add a slight shadow for separation */
  box-sizing: border-box;
}

.app-header h1 {
  font-family: 'Square Peg', cursive; /* Apply Square Peg font */
  font-size: 2.5rem; /* Increase font size */
  font-weight: 500; /* Increase font weight */
  opacity: 0.9; /* Make it slightly lighter if needed */
  margin: 0; /* Remove default margin */
  line-height: 1; /* Ensure the text fits within the header height */
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto; /* Align to the right */
}

.search-icon {
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
}

.search-icon:hover {
  color: #000;
}

.search-box {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 10px;
  width: 100%;
  max-width: 250px;
  background: rgba(255, 255, 255, 0.15); /* Glassmorphic background */
  backdrop-filter: blur(8px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(10px); /* Apply blur effect for Safari */
  border-radius: 8px;
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.search-input {
  width: 100%;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid rgba(200, 200, 200, 0.5); /* Subtle border */
  font-size: 0.9rem; /* Smaller font size */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: transparent;
  color: #000;
  outline: none; /* Remove the default outline */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.search-input:focus {
  border-color: rgba(100, 100, 100, 0.4); /* Slightly darker border on focus */
}

.search-results {
  position: absolute;
  top: calc(100% + 5px); /* Position below the search box */
  left: 0;
  right: 0; /* Ensure the dropdown stays within the viewport */
  background: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
  backdrop-filter: blur(8px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(8px); /* Apply blur effect for Safari */
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
  list-style: none; /* Remove bullets */
  padding: 0;
  margin: 0;
}

.search-results::-webkit-scrollbar {
  width: 8px;
}

.search-results::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.15); /* Glassmorphic background */
  backdrop-filter: blur(8px); /* Apply blur effect */
}

.search-results::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.4); /* Visible thumb */
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.search-results::-webkit-scrollbar-thumb:hover {
  background-color: rgba(100, 100, 100, 0.6); /* Slightly darker on hover */
}

.search-result-item {
  padding: 8px 12px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8); /* Matching background */
  backdrop-filter: blur(8px); /* Apply blur effect */
  transition: background 0.3s ease;
}

.search-result-item:hover {
  background: rgba(200, 200, 200, 0.4); /* Slightly darker background on hover */
}

.card-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  max-width: 1400px;
  width: 100%;
  margin-top: 20px; /* Add margin to avoid overlap with header */
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.2); /* Slightly transparent background */
  backdrop-filter: blur(8px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(10px); /* Apply blur effect for Safari */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease; /* Add transition for background, box-shadow, and transform */
  z-index: 1000; /* Ensure the button is above other content */
}

.scroll-to-top:hover {
  background: rgba(255, 255, 255, 0.4); /* Slightly darker on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slight shadow on hover */
}

.scroll-to-top:active {
  background: rgba(255, 255, 255, 0.6); /* Darker background on click */
  transform: scale(0.9); /* Dip effect on click */
}

.scroll-to-top i {
  font-size: 1.5rem;
  color: #333;
}

@media (min-width: 600px) and (max-width: 1079px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1080px) and (max-width: 1399px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1400px) {
  .card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1800px) {
  .card-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 600px) {
  .app-header {
    flex-direction: row;
    align-items: center;
    padding: 10px; /* Adjust padding for mobile view */
  }

  .search-container {
    width: auto;
    max-width: none;
    margin-left: auto;
  }

  .card-container {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .scroll-to-top {
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
  }

  .scroll-to-top i {
    font-size: 1.2rem;
  }
}
