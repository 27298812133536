/* src/Card.css */
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 16px;
  height: 500px;
  display: flex;
  flex-direction: column;
  background-size: 200% 200%;
  transition: background-position 0.5s ease, box-shadow 0.5s ease, border-color 0.5s ease, filter 0.5s ease;
  background-image: linear-gradient(45deg, #f0f0f0, #f8f8f8, #f0f0f0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  filter: saturate(0.5);
  position: relative;
  cursor: pointer;
}

.card:hover {
  animation: gradientAnimation 3s ease infinite;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-color: #aaa;
  filter: saturate(1);
}

.card-banner {
  padding: 4px 8px; /* Reduced padding for shorter height */
  background: rgba(255, 255, 255, 0); /* Make the banner transparent */
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); /* Add a slight border for separation */
}

.card-content-wrapper {
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-content {
  width: 100%;
  height: 100%;
  padding: 32px 16px 16px; /* Added top padding */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center align content vertically */
  align-items: center;
  transition: opacity 1s ease;
  backface-visibility: hidden;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none; /* Prevent interaction when not visible */
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(100, 100, 100, 0.4) transparent; /* For Firefox */
}

.card-content.front::-webkit-scrollbar {
  width: 6px;
}

.card-content.front::-webkit-scrollbar-track {
  background: transparent; /* Hide the track */
}

.card-content.front::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.4); /* Visible thumb */
  border-radius: 10px;
}

.card-content.front::-webkit-scrollbar-thumb:hover {
  background-color: rgba(100, 100, 100, 0.6); /* Slightly darker on hover */
}

.card-content.front::-webkit-scrollbar-corner,
.card-content.front::-webkit-scrollbar-button {
  display: none; /* Hide the scrollbar corner and buttons */
}

.card-footer {
  height: 32px;
  width: 100%;
  background: transparent;
  box-sizing: border-box;
  flex-shrink: 0;
}

.fade-in {
  opacity: 1;
  pointer-events: all; /* Enable interaction when visible */
}

.fade-out {
  opacity: 0;
  pointer-events: none;
}
  
.card h3, .card p {
  margin: 0;
  padding: 8px 0;
  word-break: break-word;
  text-align: center; /* Center align text */
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
