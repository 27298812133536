/* index.css */
@import url('https://fonts.googleapis.com/css2?family=Square+Peg&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll; /* Ensures the content is scrollable */
}

body::-webkit-scrollbar {
  width: 0px;  /* Removes the scrollbar */
  background: transparent;  /* Optional: just to ensure it looks clean */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 7px;
  background: linear-gradient(270deg, #3cb371, #2e8b57, #1e90ff, #3cb371);
  background-size: 600% 600%;
  animation: gradientShift 15s ease infinite;  /* Slower animation */
  z-index: 1000; /* Ensure the progress bar is above the header */
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
